import React from 'react'
import styled, { css } from 'styled-components'

import { BREAKPOINTS } from './shared/grid'
import Spacing from './shared/spacing'
import Theme from './shared/colors'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ImageWithSVGSupport from './image-with-svg-support'
import { H2, H3, UL, P } from './shared/typography'
import TextLink from './shared/text-link'

const PageTitle = ({ company }) => {
    return (
        <Container>
            <Header>Get in touch with us.</Header>
            {company.logo && (
                <CompanyInfo>
                    <Logo>
                        <ImageWithSVGSupport
                            file={company.logo.file}
                            fluid={company.logo.fluid}
                        />
                    </Logo>
                </CompanyInfo>
            )}
            <SocialLinks>
                {company.facebookUrl && (
                    <SocialLink>
                        <IconLink
                            href={company.facebookUrl}
                            aria-label="Facebook"
                        >
                            <Icon
                                icon={['fab', 'facebook-f']}
                                size="1x"
                                aria-hidden="true"
                            />
                            <SocialName>Facebook</SocialName>
                        </IconLink>
                    </SocialLink>
                )}
                {company.twitterUrl && (
                    <SocialLink>
                        <IconLink
                            href={company.twitterUrl}
                            aria-label="Facebook"
                        >
                            <Icon
                                icon={['fab', 'twitter']}
                                size="1x"
                                aria-hidden="true"
                            />
                            <SocialName>Twitter</SocialName>
                        </IconLink>
                    </SocialLink>
                )}
                {company.instagramUrl && (
                    <SocialLink>
                        <IconLink
                            href={company.instagramUrl}
                            aria-label="Facebook"
                        >
                            <Icon
                                icon={['fab', 'instagram']}
                                size="1x"
                                aria-hidden="true"
                            />
                            <SocialName>Instagram</SocialName>
                        </IconLink>
                    </SocialLink>
                )}
                {company.linkedInUrl && (
                    <SocialLink>
                        <IconLink
                            href={company.linkedInUrl}
                            aria-label="Facebook"
                        >
                            <Icon
                                icon={['fab', 'linkedin']}
                                size="1x"
                                aria-hidden="true"
                            />
                            <SocialName>LinkedIn</SocialName>
                        </IconLink>
                    </SocialLink>
                )}
            </SocialLinks>
            {company.siteUrl && (
                <CompanyLink>
                    <TextLink uri={company.siteUrl} inverted={1}>
                        {company.siteUrl}
                    </TextLink>
                </CompanyLink>
            )}
        </Container>
    )
}

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    margin: 0;
    padding: 4vw 20vw;
    background-color: ${Theme.ui.inverse};
    text-align: left;
`

const Header = styled(H2)`
    grid-column: span 2;
    color: ${Theme.text.inverse};
`

const Logo = styled.div`
    max-height: 120px;

    > div {
        max-height: 120px;
    }
`

const CompanyInfo = styled.div`
    grid-column: span 2;

    @media ${BREAKPOINTS.mobile} {
        grid-column: span 1;
    }
`

const CompanyLink = styled(P)`
    grid-column: span 2;
    color: ${Theme.text.inverse};
`

const SocialLinks = styled(UL)`
    grid-column: span 2;
    margin: ${Spacing.md} 0 0 0;
    padding: 0;
    list-style: none;
    text-align: center;

    @media ${BREAKPOINTS.mobile} {
        text-align: left;
        grid-column: span 1;
    }
`
const SocialLink = styled.li`
    display: inline-block;
    margin: 0 ${Spacing.xxs};
    padding: 0;
    background-color: ${Theme.ui.primary};
    border-radius: 0.9rem;
    list-style: none;
`

const IconLink = styled.a`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    color: ${Theme.ui.inverse};

    &:hover {
        color: ${Theme.text.primary};
    }
`

const Icon = styled(FontAwesomeIcon)`
    display: inline-block;
`

const SocialName = styled.span`
    position: absolute;
    left: -999em;
    display: block;
`

export default PageTitle
