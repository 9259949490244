import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import styled, { css } from 'styled-components'

import { BREAKPOINTS, GRIDS } from '../components/shared/grid'
import Theme from '../components/shared/colors'

import CompanyContact from '../components/company-contact'
import Header from '../components/global-header'
import Footer from '../components/global-footer'
import PrimaryContent from '../components/primary-content'
import PageCover from '../components/page-cover'
import PageTitle from '../components/page-title'
import PostThumb from '../components/post-thumb'
import SEO from '../components/seo'

class CompanyTemplate extends React.Component {
    render() {
        const page = get(this.props, 'data.contentfulCompany')

        return (
            <Layout location={this.props.location}>
                <SEO title={page.title} description={page.description} />
                <Header />
                <main>
                    <PageCover image={page.leadImage} text={page.companyName} />
                    <PageContent>
                        <PrimaryContent data={page.primaryContent} />
                    </PageContent>
                    <CompanyContact company={page} />
                </main>
                <Footer />
            </Layout>
        )
    }
}

export default CompanyTemplate

export const pageQuery = graphql`
    query CompanyBySlug($slug: String!) {
        site {
            siteMetadata {
                title
            }
        }
        contentfulCompany(slug: { eq: $slug }) {
            title
            slug
            companyName
            leadImage {
                ...Asset
            }
            logo {
                ...Asset
            }
            logoInverse {
                ...Asset
            }
            siteUrl
            instagramUrl
            facebookUrl
            twitterUrl
            linkedInUrl
            primaryContent {
                raw
                references {
                    # contentful_id is required to resolve the references
                    ...Expert
                    ...BlockContentList
                }
            }
        }
    }
`

const PageContent = styled.div`
    display: grid;
    padding: 0;

    ${() => GRIDS.content()}
`
